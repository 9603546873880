import { useMemo, useCallback } from 'react';

import { omit, reduce } from 'lodash';
import flatten from 'lodash/flatten';
import reverse from 'lodash/reverse';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useRevenueSystemFayeChannel } from '@src/hooks/faye/revenue_system';
import { useGetRevenueServiceDocuments } from '@src/hooks/queries/revenue_service_documents';
import { useGetTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { TID, TSection } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';
import { IPaymentProcessor } from '@src/types/payment_processors';
import {
  IRevenueServiceDocument, IRevenueServiceDocumentsFilter,
  IRevenueServiceDocumentsQueryFilter,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { ISorting, ISortingParams } from '@src/types/sorting';
import {
  ITransactionServiceDocument, ITransactionServiceDocumentFilterQuery,
  TTransactionServiceDocumentsSortColumn,
} from '@src/types/transaction_service_documents';
import {
  apiDaysForPeriod,
  endOfMonthApiDate,
  formatApiDate,
  parseApiDate,
  startOfMonthApiDate,
} from '@src/utils/date_helpers';

import { emptyRSD, paymentProcessorsForRevenueReportTypeId } from '@src/components/revenue_center/utils';
import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseRSDCollectionParams {
  revenueService: IRevenueService,
  revenueSystem: IRevenueSystem,
  paymentProcessorId?: TID,
  showOnlyUnreconciled?: boolean,
}

export interface IReconcileRSDCollection {
  query: UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
  records: IRevenueServiceDocument[],
  section: TSection,
  selectedRecords: IRevenueServiceDocument[],
  resetSelector: () => void,
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
}

const maxPageCount = 62;

const defaultRevenueSorting: ISortingParams<TRevenueServiceDocumentsSortColumn> = {
  orderColumn:    'end_date',
  orderDirection: 'desc',
};

const getPeriod = (endDate: TDateFilter | undefined): Date[] => {
  const result: Date[] = [];
  let gte = parseApiDate(endDate?.gte);
  let lte = parseApiDate(endDate?.lte);
  if (!gte) {
    gte = new Date();
    gte.setDate(1);
  }
  if (!lte) {
    lte = new Date(gte.getFullYear(), gte.getMonth() + 1, 0);
  }
  result.push(gte);
  result.push(lte);
  return result;
};

const defaultRevenueFilter: IRevenueServiceDocumentsFilter = {
  endDate: {
    gte: startOfMonthApiDate(),
    lte: endOfMonthApiDate(),
  },
  onlyUnreconciled: 'true',
};

const filterRevenueDataToQuery = (
  data: IRevenueServiceDocumentsFilter,
  allPaymentProcessors: IPaymentProcessor[],
  paymentProcessorId?: TID,
): IRevenueServiceDocumentsQueryFilter => {
  const queryData: IRevenueServiceDocumentsQueryFilter = omit(data, ['endDate', 'onlyUnreconciled']);

  if (data?.endDate) {
    const period = getPeriod(data.endDate);
    queryData.endDate = {
      gte: formatApiDate(period[0]),
      lte: formatApiDate(period[1]),
    };
  }

  queryData.state = 'verified';

  if (paymentProcessorId && data?.onlyUnreconciled === 'true') {
    queryData.withoutReconciledByPaymentProcessor = paymentProcessorId;
  }

  const ppCodes = allPaymentProcessors.map((pp) => pp.code);
  return reduce(queryData, (result, value, key) => {
    if (!ppCodes.includes(key)) return { ...result, [key]: value };

    return {
      ...result,
      paymentProcessors: [
        ...(result.paymentProcessors || []),
        {
          code: key,
          value,
        },
      ],
    } as IRevenueServiceDocumentsQueryFilter;
  }, {} as IRevenueServiceDocumentsQueryFilter);
};

const shouldReturnRecords = (
  filter: IRevenueServiceDocumentsFilter | undefined,
): boolean => {
  if (filter?.onlyUnreconciled) return true;

  return filter ? Object.keys(filter).some((key) => !['endDate', 'month'].includes(key)) : false;
};

const recordsWithMissedDays = (
  revenueServiceId: TID,
  records: IRevenueServiceDocument[],
  filter: IRevenueServiceDocumentsFilter | undefined,
  sortingData: ISortingParams<TRevenueServiceDocumentsSortColumn>,
): IRevenueServiceDocument[] => {
  if (shouldReturnRecords(filter)) return records;
  const period = getPeriod(filter?.endDate);
  let days = apiDaysForPeriod(formatApiDate(period[0]), formatApiDate(period[1]));

  if (sortingData.orderColumn === 'end_date') {
    if (sortingData.orderDirection === 'desc') {
      days = reverse(days);
    }

    return days.map((date) => {
      const document = records.find((d) => d.endDate === date);

      return document || emptyRSD(revenueServiceId, date, []);
    });
  }

  const existDays = records.map((r) => r.endDate);

  if (sortingData.orderDirection === 'asc') {
    const filteredDates = days.filter((d) => !existDays.includes(d));
    return [
      ...filteredDates.map((date) => emptyRSD(revenueServiceId, date, [])),
      ...records,
    ];
  }

  return [
    ...records,
    ...reverse(days).filter((d) => !existDays.includes(d))
      .map((date) => emptyRSD(revenueServiceId, date, [])),
  ];
};

interface IUseTSDCollectionParams {
  businessId: TID,
  paymentProcessorId: TID | undefined,
  revenueSystemId: string,
  revenueSection: TSection,
}

interface ITSDCollected {
  query: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  section: TSection,
  selectedRecords: ITransactionServiceDocument[],
  resetSelector: () => void,
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
}

const tsdDefaultSorting: ISortingParams<TTransactionServiceDocumentsSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const transactionsFilterToQuery = (
  filterData: ITransactionServiceDocumentFilterQuery | undefined,
  revenueFilterData: IRevenueServiceDocumentsFilter,
  paymentProcessorId: TID | undefined,
): ITransactionServiceDocumentFilterQuery | undefined => {
  return {
    ...filterData,
    processorId:              String(paymentProcessorId),
    withoutRevenueReconciled: revenueFilterData?.onlyUnreconciled === 'true',
  };
};

const transactionTypes = ['revenue', 'revenue_reversal', 'investment', 'other_income'];

const useReconciliationRSDCollection = ({
  revenueService,
  revenueSystem,
  paymentProcessorId,
}: IUseRSDCollectionParams): IReconcileRSDCollection => {
  const section = useMemo(() => ({
    revenueSystemId: revenueSystem.id,
    section:         Section.ReconcileRevenue,
  }), [revenueSystem]);
  const allPaymentProcessors = paymentProcessorsForRevenueReportTypeId({
    revenueReportTypeId: revenueSystem.revenueReportTypeId,
    revenueService,
  });

  const filterData = useFilterData(section, defaultRevenueFilter);
  const filterQuery = useMemo(() => {
    return filterRevenueDataToQuery(
      filterData,
      allPaymentProcessors,
      paymentProcessorId,
    );
  }, [filterData, allPaymentProcessors, paymentProcessorId]);

  const sorting = useSorting<TRevenueServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: defaultRevenueSorting,
  });

  const query = useGetRevenueServiceDocuments({
    revenueServiceId: revenueService.id,
    revenueSystemId:  revenueSystem.id,
    filter:           filterQuery,
    perPage:          maxPageCount,
    ...sorting.data,
  });

  const { refetch } = query;
  const handleEventReceived = useCallback(() => {
    refetch();
  }, [refetch]);

  useRevenueSystemFayeChannel(revenueSystem.id, handleEventReceived);

  const records: IRevenueServiceDocument[] = useMemo(() => {
    if (query.isLoading) return [];

    // Start date has wrong format in the RevenueServiceDocument API
    const fixedDays = (query?.data?.collection || []).map((d) => ({
      ...d,
      startDate: formatApiDate(d.startDate),
      rowKey:    `${formatApiDate(d.startDate)}-${d.id}`,
      endDate:   formatApiDate(d.endDate),
    })) || [];

    return recordsWithMissedDays(
      revenueService.id,
      fixedDays,
      filterData,
      sorting.data,
    );
  }, [
    filterData,
    query?.data?.collection,
    query.isLoading,
    revenueService.id,
    sorting.data,
  ]);

  const { selected: selectedRowKeys, reset: resetSelector } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedRowKeys.includes(r.rowKey));
  }, [selectedRowKeys, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    resetSelector,
    sorting,
  };
};

const useTSDCollection = ({
  businessId,
  paymentProcessorId,
  revenueSystemId,
  revenueSection,
}: IUseTSDCollectionParams): ITSDCollected => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.ReconcileIncome,
    };
  }, [businessId]);

  const revenueFilterData = useFilterData(revenueSection);
  const filterData = useFilterData(section);
  const filterQuery = useMemo(() => {
    return transactionsFilterToQuery(filterData, revenueFilterData, paymentProcessorId);
  }, [filterData, revenueFilterData, paymentProcessorId]);

  const sorting = useSorting<TTransactionServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tsdDefaultSorting,
  });

  const query = useGetTransactionServiceDocuments({
    businessId,
    excluded:            false,
    transactionType:     transactionTypes,
    reconciliationScope: true,
    state:               'verified',
    filter:              filterQuery,
    ...sorting.data,
  });

  const { refetch } = query;
  const handleEventReceived = useCallback(() => {
    refetch();
  }, [refetch]);

  useRevenueSystemFayeChannel(revenueSystemId, handleEventReceived);

  const records = useMemo(() => {
    const pages = query.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds, reset: resetSelector } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    resetSelector,
    sorting,
  };
};

export {
  ITSDCollected,
  useReconciliationRSDCollection,
  useTSDCollection,
};
